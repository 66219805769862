import React from 'react';
import { Link } from 'react-router-dom';
import './newHeader.css';

const NewHeader = () => {
  return (
    <div className="new-header">
      <nav className="new-header-nav">
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact</Link>
      </nav>
    </div>
  );
};

export default NewHeader;
