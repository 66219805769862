// src/components/footer/Footer.js
import React from 'react';
import './footer.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaPinterest, FaMapMarkerAlt, FaEnvelope, FaWhatsapp, FaPhone } from 'react-icons/fa';

const Footer = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100); // Delay to ensure the contact page is rendered
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left-section">
          <h3>
            <span className="footer-link" onClick={handleContactClick}>Connect with Our Experts</span>
          </h3>
          <p>
            Ecosystem bootstrapping learning curve lean startup disruptive. Marketing long tail
            disruptive agile development partner.
          </p>
          <div className="social-icons">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
              <FaPinterest />
            </a>
          </div>
        </div>
        <div className="right-section">
          <div className="contact-box">
            <FaMapMarkerAlt />
            <p>Shop no 1, Maruti Gate no 2, near SBI ATM, Sec 18, Gurgaon, Haryana</p>
          </div>
          <div className="contact-box">
            <FaEnvelope />
            <p>admin@mdinfotechsolutions.in</p>
          </div>
          <div className="contact-box">
            <FaWhatsapp />
            <p>+91 9560394421</p>
          </div>
          <div className="contact-box">
            <FaPhone />
            <p>+91 9560394421</p>
          </div>
          <div className="contact-box">
            <p>GSTIN: 06DGRPP4014B1ZK</p>
          </div>
          <div className="contact-box">
            <FaMapMarkerAlt />
            <a href="https://g.co/kgs/YCY1R1h" target="_blank" rel="noopener noreferrer">Find us on Google Maps</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 MD Infotech Solutions. All rights reserved. Developed by <a href="https://algdev.in" target="_blank" rel="noopener noreferrer" className="footer-link">ALGDEV.in</a>.
      </div>
    </footer>
  );
};

export default Footer;
