import React from 'react';
import VisionMission from '../vision-mission/VisionMission';
import Partners from '../partners/Partners';
import Clients from '../clients/Clients';
import Header from '../Header/header';

const Home = () => {
  return (
    <div>
        <Header/>
      <VisionMission />
      <Partners />
      <Clients />
    </div>
  );
};

export default Home;
