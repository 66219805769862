import React from 'react';
import './clients.css';
import lntLogo from './dabur.png';
import omaxeLogo from './Educomp.png';
import foodpandaLogo from './foodpanda.png';
import daburLogo from './L&T.png';
import pumaLogo from './omaxe.png';
import educompLogo from './puma.png';

const Clients = () => {
  const clients = [
    { name: '  L & T  ', logo: lntLogo },
    { name: '  OMAXE  ', logo: omaxeLogo },
    { name: 'Foodpanda', logo: foodpandaLogo },
    { name: '  Dabur  ', logo: daburLogo },
    { name: '  Puma   ', logo: pumaLogo },
    { name: ' Educomp ', logo: educompLogo },
  ];

  return (
    <div className="clients">
      <h2>Our Esteemed Clients</h2>
      <div className="clients-grid">
        {clients.map((client, index) => (
          <div key={index} className="client">
            <img src={client.logo} alt={client.name} className="client-logo" />
            <p>{client.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;