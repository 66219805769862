import React from 'react';
import NewHeader from '../Header/header2';
import './about.css';
import aboutImage from './services.png'; // Add your own image path
import Offerings from '../Offerings/Offerings';

const About = () => {
  return (
    <div>
      <NewHeader />
      <div className="about">
        <div className="about-content">
          <h2>About Us</h2>
          <p>Welcome to MD Infotech Solutions – Your Complete Solution Provider for Hardware & Networking, and Software Development. Based in Gurgaon, India, we have been dedicated to delivering expert services for over three years. Our commitment to excellence and innovation ensures that we meet the unique needs of our clients, providing reliable and cutting-edge solutions.</p>
        </div>
        <div className="about-image">
          <img src={aboutImage} alt="About Us" />
        </div>
      </div>
      <Offerings />
    </div>
  );
};

export default About;
