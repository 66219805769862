import React, { useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as THREE from 'three';
import './header.css';

const Particles = () => {
  const ref = useRef();
  const mouse = useRef([0, 0]);
  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < 20000; i++) { // Increase the number of particles
      const x = Math.random() * 800 - 400; // Increase spread
      const y = Math.random() * 800 - 400; // Increase spread
      const z = Math.random() * 800 - 400; // Increase spread
      temp.push(x, y, z);
    }
    return new Float32Array(temp);
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      mouse.current = [event.clientX - window.innerWidth / 2, event.clientY - window.innerHeight / 2];
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useFrame(() => {
    ref.current.rotation.x += 0.0005; // Slower continuous rotation
    ref.current.rotation.y += 0.0005; // Slower continuous rotation

    // Adjust rotation with mouse movement
    ref.current.rotation.x += mouse.current[1] * 0.0001;
    ref.current.rotation.y += mouse.current[0] * 0.0001;

    // Emit particles on mouse movement
    const positions = ref.current.geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
      positions[i] += Math.sin(positions[i] + ref.current.rotation.y) * 0.5;
      positions[i + 1] += Math.cos(positions[i + 1] + ref.current.rotation.x) * 0.5;
      positions[i + 2] += Math.sin(positions[i + 2] + ref.current.rotation.x) * 0.5;
    }
    ref.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <Points ref={ref} positions={particles} stride={3}>
      <PointMaterial color="#ffffff" size={0.5} />
    </Points>
  );
};

const Header = () => {
  return (
    <div className="header">
      <Canvas camera={{ position: [0, 0, 600], fov: 75 }}>
        <Particles />
      </Canvas>
      <div className="header-content">
        <nav>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
        </nav>
        <div className="title">
          <h1>MD Infotech Solutions</h1>
          <p>Innovate, Upgrade, Succeed</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
