import React from 'react';
import './partners.css';
import hpLogo from './hp.png';
import dellLogo from './dell.png';
import lenovoLogo from './lenovo.png';
import ibmLogo from './ibm.png';
import ciscoLogo from './cisco.png';
import microtekLogo from './microtek.png';
import quickhealLogo from './quickheal.png';
import hikvisionLogo from './hikvision.png';
import microsoftLogo from './microsoft.png';
import tallyLogo from './tally.png';

const Partners = () => {
  const partners = [
    { name: 'HP', logo: hpLogo },
    { name: 'Dell', logo: dellLogo },
    { name: 'Lenovo', logo: lenovoLogo },
    { name: 'IBM', logo: ibmLogo },
    { name: 'Cisco', logo: ciscoLogo },
    { name: 'Microtek', logo: microtekLogo },
    { name: 'Quick Heal', logo: quickhealLogo },
    { name: 'Hikvision', logo: hikvisionLogo },
    { name: 'Microsoft', logo: microsoftLogo },
    { name: 'Tally', logo: tallyLogo },
  ];

  return (
    <div className="partners">
      <h2>Our Partners</h2>
      <div className="partners-grid">
        {partners.map((partner, index) => (
          <div key={index} className="partner">
            <img src={partner.logo} alt={partner.name} className="partner-logo" />
            <div className="sparkles"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
