import React, { useState, useRef } from 'react';
import Header2 from '../Header/header2';
import './allServices.css';
import rentalIcon from './rental/rental.png'
import importIcon from './imported/importedLaptop.png'
import websiteIcon from './website/website.png'
import applicationIcon from './application/application.png'



import hardwareIcon from './backup.png';
import signageIcon from './hardware.png';
import securityIcon from './networking.png';
import powerIcon from './security.png';
import softwareIcon from './signage.jpg';
import networkingIcon from './software.png';
import appleMacbook from './hardware/apple-macbook.png';
import lenovoThinkpad from './hardware/lenovo-thinkpad.png';
import hpLaptops from './hardware/hp-laptop.png';
import hpPavilion from './hardware/hp-pavilion.png';
import workstations from './hardware/workstations.png';
import desktops from './hardware/desktop.png';
import dellLaptops from './hardware/dell-laptop.png';
import dellServer from './hardware/dell-server.png';
import signageDisplay from './display/signage-display.png';
import touchScreen from './display/touch-screen.jpg';
import videoWall from './display/video-wall.jpg';
import securitySurveillance from './security/security-surveillance.png';
import cctv from './security/cctv.png';
import accessControl from './security/access-control.png';
import powerSolutions from './power/power-solutions.png';
import ups from './power/ups.png';
import batteryBackup from './power/battery-backup.png';
import microsoft365 from './software/microsoft.png';
import tally from './software/tally.png';
import quickheal from './software/quickheal.png';
import symantec from './software/symantec.png';
import compNetworking from './networking/compNetworking.jpeg';
import lanwan from './networking/lanwan.png';
import networkTroubleshooting from './networking/networkTroubleshooting.jpg';
import opticalFiber from './networking/optical-fiber.png';
import iotIcon from './iot/iotIcon.png'

const hardwareItems = [
  { name: 'Apple MacBook', image: appleMacbook },
  { name: 'Lenovo ThinkPad', image: lenovoThinkpad },
  { name: 'HP Laptops', image: hpLaptops },
  { name: 'HP Pavilion x360', image: hpPavilion },
  { name: 'Workstations', image: workstations },
  { name: 'Desktops', image: desktops },
  { name: 'Dell Laptops', image: dellLaptops },
  { name: 'Dell Server', image: dellServer },
];

const displayItems = [
  { name: 'Signage Display', image: signageDisplay },
  { name: 'Touch Screen', image: touchScreen },
  { name: 'Video Wall', image: videoWall },
];

const securityItems = [
  { name: 'Security Surveillance', image: securitySurveillance },
  { name: 'CCTV', image: cctv },
  { name: 'Access Control', image: accessControl },
];

const powerItems = [
  { name: 'Power Solutions', image: powerSolutions },
  { name: 'UPS', image: ups },
  { name: 'Battery Backup', image: batteryBackup },
];

const softwareItems = [
  { name: 'Microsoft 365', image: microsoft365 },
  { name: 'Tally', image: tally },
  { name: 'QuickHeal', image: quickheal },
  { name: 'Symantec', image: symantec },
];

const networkingItems = [
  { name: 'Computer Networking', image: compNetworking },
  { name: 'LAN/WAN', image: lanwan },
  { name: 'Network Troubleshooting', image: networkTroubleshooting },
  { name: 'Optical Fiber', image: opticalFiber },
];

const rentalSystemItems = [
  { name: 'CCTV' },
  { name: 'Server' },
  { name: 'Laptop' },
  { name: 'PC' }
];

const importedLaptopItems = [
  { name: 'Imported Laptop' },
  { name: 'Imported Macbook' },
  { name: 'Imported Server' },
  { name: 'Imported PC' },
];

const websiteDesigningItems = [
  { name: 'Website for College and University' },
  { name: 'Website for Company' },
  { name: 'Website for Shops, Online Shopping' },
  { name: 'Custom websites as per requirement' }
];

const applicationDevelopmentItems = [
  { name: 'Android application developement' },
  { name: 'Windows application development' },
  { name: 'IoS application development' },
];

const ioTItems = [
  { name: 'College Projects' },
  { name: 'Company Projects' },
  { name: 'Special Purpose' },
];

const services = [
  {
    name: 'Hardware Solutions',
    icon: hardwareIcon,
    description: (
      <div>
        <p>Personal & Commercial Computers- Laptops, Desktops & Servers, Workstations.</p>
      </div>
    ),
    items: hardwareItems,
  },
  {
    name: 'Display & Digital Signage',
    icon: signageIcon,
    description: 'High definition, high brightness models, touch screen, glass free 3D displays, large format video walls.',
    items: displayItems,
  },
  {
    name: 'Security And Surveillance',
    icon: securityIcon,
    description: 'Security and access control systems, CCTV cameras for seamless control and security.',
    items: securityItems,
  },
  {
    name: 'Power Solutions',
    icon: powerIcon,
    description: 'Power backup solutions tailored to client needs with experienced engineers.',
    items: powerItems,
  },
  {
    name: 'Software Solutions',
    icon: softwareIcon,
    description: 'Full range of Microsoft software packages, antivirus solutions, accounting software, media & DTP solutions.',
    items: softwareItems,
  },
  {
    name: 'Networking Solutions',
    icon: networkingIcon,
    description: 'LAN / WAN installation, network troubleshooting, robust and secure network solutions.',
    items: networkingItems,
  },
  {
    name: 'Rental System',
    icon: rentalIcon, // Use an appropriate icon here
    description: 'Rental systems for various needs and durations.',
    items: rentalSystemItems,
  },
  {
    name: 'Imported Laptop/PC',
    icon: importIcon, // Use an appropriate icon here
    description: 'High-quality imported laptops for personal and business use.',
    items: importedLaptopItems,
  },
  {
    name: 'Website Design and Management',
    icon: websiteIcon, // Use an appropriate icon here
    description: 'Professional website designing, management and hosting services.',
    items: websiteDesigningItems,
  },
  {
    name: 'Application Development',
    icon: applicationIcon, // Use an appropriate icon here
    description: 'Custom application development for various platforms and needs. For e.g. Android, Windows applications',
    items: applicationDevelopmentItems,
  },
  {
    name: 'IoT Projects',
    icon: iotIcon, // Use an appropriate icon here
    description: 'We provide innovative solutions for connecting and automating devices using Arduino IoT.',
    items: ioTItems,
  }
];

const AllServices = () => {
  const [expandedService, setExpandedService] = useState(null);
  const sectionRefs = useRef(services.reduce((acc, value) => {
    acc[value.name] = React.createRef();
    return acc;
  }, {}));

  const toggleExpand = (serviceName) => {
    setExpandedService(expandedService === serviceName ? null : serviceName);
    if (expandedService !== serviceName) {
      setTimeout(() => {
        sectionRefs.current[serviceName].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  };

  return (
    <div className="all-services-page">
      <Header2 />
      <div className="all-services">
        <h2>Our Services</h2>
        <div className="services-list">
          {services.map((service, index) => (
            <div
              className={`service-item ${expandedService === service.name ? 'expanded' : ''}`}
              key={index}
              ref={sectionRefs.current[service.name]}
            >
              <div className="service-header" onClick={() => toggleExpand(service.name)}>
                <img src={service.icon} alt={service.name} className="service-icon" />
                <div className="service-details">
                  <h3>{service.name}</h3>
                  {service.description}
                </div>
              </div>
              {expandedService === service.name && (
                <div className="service-items">
                  {service.items.length > 0 ? (
                    <div className="hardware-items">
                      <h4>Available {service.name}</h4>
                      <div className="hardware-grid">
                        {service.items.map((item, idx) => (
                          <div key={idx} className="hardware-card">
                            {item.image && <img src={item.image} alt={item.name} className="hardware-image" />}
                            <p>{item.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p>No additional items to display.</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllServices;
