import React from 'react';
import { Link } from 'react-router-dom';
import './offerings.css';
import amcIcon from './amc.jpg';
import cctvIcon from './cctv.png';
import networkingIcon from './networking.png';
import tvIcon from './tv.jpeg';
import projectorIcon from './projector.jpg';
import accessoriesIcon from './accessories.jpg';
import newLaptopIcon from './new-laptop.jpg';
import onlineUpsIcon from './online-ups.jpg';
import rentalIcon from './rental.png'
import importIcon from './importedLaptop.png'
import websiteIcon from './website.png'
import applicationIcon from './application.png'
import iotIcon from './iot.png'

const offeringIcons = [
  { name: 'AMC', icon: amcIcon },
  { name: 'CCTV', icon: cctvIcon },
  { name: 'Networking', icon: networkingIcon },
  { name: 'TV', icon: tvIcon },
  { name: 'Projector', icon: projectorIcon },
  { name: 'Accessories', icon: accessoriesIcon },
  { name: 'New Laptop', icon: newLaptopIcon },
  { name: 'Rental Systems', icon: rentalIcon },
  { name: 'Imported Laptop/PC', icon: importIcon },
  { name: 'Website Design', icon: websiteIcon },
  { name: 'Application Developement', icon: applicationIcon },  
  { name: 'Customised IoT Projects', icon: iotIcon },
];

const Offerings = () => {
  return (
    <div className="offerings">
      <h2>Our Offerings</h2>
      <div className="offerings-grid">
        {offeringIcons.map((offering, index) => (
          <div className="offering-card-link" key={index}>
            <div className="offering-card">
              <img src={offering.icon} alt={offering.name} className="offering-icon" />
              <h3>{offering.name}</h3>
              <Link to="/contact" className="know-more-link"> <p>Know More</p></Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offerings;
