import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import './vision-mission.css';

const VisionMission = () => {
  return (
    <div className="vision-mission">
      <div className="vision">
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 0, 5]} />
          <Text position={[0, 3, 0]} fontSize={0.8} color="white">
            Our Vision
          </Text>
          
          
          <Text position={[0, -0.5, 0]} fontSize={0.4} color="white" maxWidth={5}>
            "To provide value performance and service excellence, enhance life of infrastructure and provide reliable solutions at all times to our customers."
          </Text>
        </Canvas>
      </div>
      <div className="mission">
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 0, 5]} />
          <Text position={[0, 3, 0]} fontSize={0.8} color="white">
            Our Mission
          </Text>
          
          <Text position={[0, -0.5, 0]} fontSize={0.4} color="white" maxWidth={5}>
            "To be the IT infrastructure partner of choice for esteem customers by collaboratively providing right and quality solutions and transforming technology into business opportunities."
          </Text>
        </Canvas>
      </div>
    </div>
  );
};

export default VisionMission;
