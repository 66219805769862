import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/header';
import Home from './components/Home/Home';
import VisionMission from './components/vision-mission/VisionMission';
import Partners from './components/partners/Partners';
import Clients from './components/clients/Clients';
import About from './components/About/About';
import Contact from './components/contact/Contact';
import AllServices from './components/services/AllServices';
import Footer from './components/footer/Footer';
import './App.css';
//This is test comment

const App = () => {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<AllServices />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
