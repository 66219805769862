import React, { useState } from 'react';
import './contact.css';
import NewHeader from '../Header/header2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css'; // Import custom CSS
import { ClipLoader } from 'react-spinners'; // Import the spinner

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [query, setQuery] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const validateForm = () => {
    if (contactNo.trim() === '' && email.trim() === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isFormValid) {
      setIsLoading(true); // Set loading to true
      const formData = { firstName, lastName, contactNo, email, query };
      try {

        const response = await fetch('https://backendmdinfotech.mdinfotechsolutions.in/add', {
        
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          toast.success('Form submitted successfully', {
            className: 'toast-success',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress-success'
          });
          // Optionally, reset the form here
          setFirstName('');
          setLastName('');
          setContactNo('');
          setEmail('');
          setQuery('');
        } else {
          toast.error('Form submission failed', {
            className: 'toast-error',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress-error'
          });
        }
      } catch (error) {
        toast.error('An error occurred while submitting the form', {
          className: 'toast-error',
          bodyClassName: 'toast-body',
          progressClassName: 'toast-progress-error'
        });
      } finally {
        setIsLoading(false); // Set loading to false after the request completes
      }
    } else {
      toast.error('Please provide either contact number or email', {
        className: 'toast-error',
        bodyClassName: 'toast-body',
        progressClassName: 'toast-progress-error'
      });
    }
  };

  return (
    <div>
      <NewHeader />
      <ToastContainer />
      <div className="contact">
        <h2>Contact Us</h2>
        <div className="contact-form">
          <h3>Connect with Our Experts</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name <span className="required">*</span></label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  maxLength="50"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  maxLength="50"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="contactNo">Contact Number</label>
                <input
                  type="tel"
                  id="contactNo"
                  name="contactNo"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {!isFormValid && (
              <p className="error-message">Please provide either contact number or email</p>
            )}
            <div className="form-group">
              <label htmlFor="query">Your Query</label>
              <textarea
                id="query"
                name="query"
                rows="5"
                maxLength="1000"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : 'Submit'}
            </button>
          </form>
        </div>
        <div className="address-block">
          <p>Shop no 1, Maruti Gate no 2, near SBI ATM,</p>
          <p>Sector 18, Gurgaon, Haryana</p>
          <p>Email: admin@mdinfotechsolutions.in</p>
          <p>Phone: +91 9560394421</p>
          <p>WhatsApp: +91 9560394421</p>
        </div>
        <div className="map-container">
          <iframe
            title="Google Map"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448181.59993079915!2d76.81307378615226!3d28.646677258803503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1db50f235d29%3A0xa93b663253b6a196!2sGurgaon%2C%20Haryana%2C%20India!5e0!3m2!1sen!2sus!4v1629898656493!5m2!1sen!2sus`}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
